<template>
  <v-container fill-height>
    <v-layout align-top justify-center>
      <v-flex xs12 sm10 md10 lg10>
        <v-sheet color="white" elevation="20" class="pa-2">
          <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          :stopCamera="stopCamera"
        />
        <v-data-table
        :headers="headers"
        :items="inventory_lst"
        :items-per-page="-1"
        class="elevation-4"
        mobile-breakpoint="0"
        item-key="ean"
        :loading="loading_status"
        loader-height="10"
        loading-text="Cargando ..."
        
      >
        <template v-slot:top> </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="add2cart(item)">
            mdi-plus-box-outline
          </v-icon>
        </template>
      </v-data-table>
        </v-sheet>
      </v-flex>
    </v-layout>
    <v-bottom-navigation fixed app>
      <v-spacer></v-spacer>
      <!-- <v-form @submit="get_product" class="pa-2">
        <v-text-field
          v-model="sku"
          id="sku"
          ref="skuID"
          label=""
          :loading="loading_status"
          loader-height="10"
          placeholder="Código de Barras"
          hide-details
          dense
          outlined
          autofocus
          clearable
          prepend-inner-icon="mdi-barcode-scan"
          append-icon="mdi-help"
          @change="get_product"
          @click:append="get_product"
        >
        </v-text-field>
      </v-form> -->
      <v-spacer></v-spacer>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import { StreamBarcodeReader } from "../utils/barcodereader.js";

export default {
  components: {  StreamBarcodeReader },
  data() {
    return {
      errors: [],
      valid: false,
      loading_status: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      imageUrl: null,
      dialog: false,
      credit_notes: [],
      skuError: false,
      sku: null,
      stopCamera: false,
      initCamera: false,
      result: "",
      inventory_lst:[],
      headers: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          value: "groupName",
          dataType: "text",
        },

        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "brand",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {},
  methods: {
    onLoaded(e) {
      console.log(e);
    },
    onDecode(e) {
     // this.stopCamera = true;
      //this.QRdialog = false;
      this.sku=e;
      this.get_product(e);
      console.log(e);
    },
    get_product(e) {
      //e.preventDefault();
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: {
          id_pos: window.store.store_id,
          ean: e,
        },
      };
      //this.sku = null;
      //console.log(qry);
      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        if (data.length>0) {
          this.inventory_lst=data;
        } else {
          this.skuError = true;
        }
      });
    },
  },
};
</script>

<style></style>
